import { Button } from "../common";
import { useAuthContext } from "../../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { useState } from "react";

interface User {
    email: string;
    password: string;
}


const LoginCard = () => {
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, formState: { errors },watch} = useForm<User>();
    const [showPassword, setShowPassword] = useState(false);
    const { login } = useAuthContext();
    const navigate = useNavigate();
    const location = useLocation();
    const from = (location.state as { from?: Location })?.from?.pathname || '/';
    const email=watch("email");
    const password=watch("password");


    const onSubmit: SubmitHandler<User> = async (data) => {
        setLoading(true)
        const isLoggedIn = await login(data);
        setLoading(false)
        if (from && isLoggedIn) navigate(from, { replace: true });
        if (!from && isLoggedIn) navigate('/dashboard');
    };

    return (
        <div className="login-form">
            <span className="fw-normal">Log in</span>
            <h2 className="fw-semibold mb-4 pb-xxl-3">Hello! Welcome back.</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3 pb-xxl-2 pb-1">
                    <label htmlFor="email" className="form-label fw-normal">Email address</label>
                    <input
                        {...register("email", { required: "Email is required." })}
                        type="email"
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                        id="email"
                        placeholder="Enter email address"
                    />
                    {errors.email && <div className="error-message">{errors.email.message}</div>}
                </div>
                <div className="mb-3 pb-xxl-2 pb-1">
                    <label htmlFor="password" className="form-label">Password</label>
                    <div className="position-relative pw-eye">
    <input
        {...register("password", { required: "Password is required." })}
        type={showPassword ? "text" : "password"}
        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
        id="password"
        placeholder="Enter Password"
    />
    
    {showPassword ? (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            fill="currentColor"
            className="bi bi-eye-slash position-absolute"
            viewBox="0 0 16 16"
            onClick={() => setShowPassword(!showPassword)}
        >
            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z"/>
            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829"/>
            <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z"/>
        </svg>
    ) : (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            className="position-absolute"
            onClick={() => setShowPassword(!showPassword)}
        >
            <path
                d="M21.1303 9.95827C22.2899 11.1751 22.2899 13.0238 21.1303 14.2406C19.1745 16.2928 15.8155 19.0807 12 19.0807C8.18448 19.0807 4.82549 16.2928 2.86971 14.2406C1.7101 13.0238 1.7101 11.1751 2.86971 9.95827C4.82549 7.90601 8.18448 5.11816 12 5.11816C15.8155 5.11816 19.1745 7.90601 21.1303 9.95827Z"
                stroke="#808095"
            />
            <path
                d="M15 12.0994C15 13.7518 13.6569 15.0914 12 15.0914C10.3431 15.0914 9 13.7518 9 12.0994C9 10.447 10.3431 9.10745 12 9.10745C13.6569 9.10745 15 10.447 15 12.0994Z"
                stroke="#808095"
            />
        </svg>
    )}
</div>


                    {errors.password && <div className="error-message">{errors.password.message}</div>}
                </div>
                <div className="d-flex align-items-center justify-content-between pb-xxl-3 pb-2 mb-3">
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                        <label className="form-check-label" htmlFor="exampleCheck1">Stay logged in</label>
                    </div>
                    <div className="forgot-pw">
                        <a href="#">Forgot Password?</a>
                    </div>
                </div>
                <Button type="submit" className={`btn-primary fw-medium w-100 border-0 mb-4 login-btn ${email&&password?"opacity-100":"opacity-50"}`} disabled={loading}>
                    {loading ? "Logging in..." : "Login"}
                </Button>
                <p className="m-0 text-center">By signing in, you agree to our <a href="#">Terms & Conditions</a></p>
            </form>
        </div>
    )
}

export default LoginCard