import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { routes } from './route-config';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const App = () => {
  return (
    <Router>
       <ToastContainer />
      <Routes>
      {routes.map(({ path, element }, index) => (
        <Route key={index} path={path} element={element} />
      ))}
      </Routes>
    </Router>
  )
}

export default App
