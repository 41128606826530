import { useEffect, useRef, useCallback } from 'react';

// Custom Hook for detecting clicks outside of an element
const useClickOutside = (handler: () => void,) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      handler(); // Call the handler when click outside is detected
    }
  }, [handler]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return ref;
};

export default useClickOutside;
