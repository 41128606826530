import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center vh-100 bg-light">
            <h1 className="display-1 fw-bold text-danger">404</h1>
            <p className="lead">Sorry, the page you are looking for does not exist.</p>
            <Link to="/dashboard" className="btn btn-primary mt-3">Go to Home</Link>
        </div>
    )
}

export default NotFound