import { useState } from 'react';

type UseToggleReturnType = [boolean, (value?:boolean) => void];

const useToggle = (initialState: boolean): UseToggleReturnType => {
  // Use useState hook to manage the state
  const [state, setState] = useState(initialState);

  // Define a function to toggle the state
  const toggle = (value?: boolean) => {
    if (typeof value === 'boolean') {
      setState(value);
    } else {
      setState((currentState) => !currentState);
    }
  };

  // Return the current state and the toggle function
  return [state, toggle];
};

export default useToggle;
