import axios from "axios";
import { BACKEND_BASE_URL } from "../constant/index";

// Create an instance of Axios
const axiosInstance = axios.create({
  baseURL: BACKEND_BASE_URL, // Replace with your API base URL
});

// List of routes that do not require authentication
const noAuthRoutes = ["/signup", "/login"];

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Check if the request URL matches any of the noAuthRoutes
    const isNoAuthRoute = noAuthRoutes.some((route) => config.url?.includes(route));

    // If the route is not in the noAuthRoutes list, add the Authentication header
    if (!isNoAuthRoute) {
      const token = localStorage.getItem("adminSessionToken"); // Get the token from localStorage or any other storage
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Optionally add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
