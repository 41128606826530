import React, { FC, Suspense } from "react";
import { Home, Login, NotFound } from "./pages";
import { Loader } from "./components/common";
import ProtectedRoute from "./ProtectedRoute";

interface RouteConfig {
  path: string;
  element: React.ReactElement
}

const Dashboard = React.lazy(() => import("./pages/Dashboard/Dashboard"));
const Interviews = React.lazy(() => import("./pages/Interviews/Interviews"));
const Interview = React.lazy(() => import("./pages/interview/Interview"));
const PostJob = React.lazy(() => import("./pages/PostJob/PostJob"));
const AddCandidate = React.lazy(() => import("./pages/AddCandidate/AddCandidate"));

const SuspendedDashboard: FC = () => (
  <Suspense fallback={<div><Loader /></div>}>
    <Dashboard />
  </Suspense>
);

const SuspendedInterviews: FC = () => (
  <Suspense fallback={<div><Loader /></div>}>
    <Interviews />
  </Suspense>
)

const SuspendedInterview: FC = () => (
  <Suspense fallback={<div><Loader /></div>}>
    <Interview />
  </Suspense>
);

const SuspendedPostJob: FC = () => (
  <Suspense fallback={<div><Loader /></div>}>
    <PostJob />
  </Suspense>
)

const SuspendedAddCandidate: FC = () => (
  <Suspense fallback={<div><Loader /></div>}>
    <AddCandidate />
  </Suspense>
)




export const routes: RouteConfig[] = [
  { path: "/", element: <ProtectedRoute element={<Home />} /> },
  { path: "/login", element: <Login /> },
  { path: "/dashboard", element: <ProtectedRoute element={<SuspendedDashboard />} /> },
  { path: "/interviews", element: <ProtectedRoute element={<SuspendedInterviews />} /> },
  { path: "/interview", element: <ProtectedRoute element={<SuspendedInterview />} /> },
  { path: "/post-job", element: <ProtectedRoute element={<SuspendedPostJob />} /> },
  { path: "/edit-job/:jobId", element: <ProtectedRoute element={<SuspendedPostJob />} /> },
  { path: "/add-candidate", element: <ProtectedRoute element={<SuspendedAddCandidate />} /> },
  { path: "*", element: <NotFound /> },
];
