import { LoginMember, Logo } from "../../assets/images/login";
import { Image } from "../../components/common";
import LoginCard from "../../components/Login/LoginCard";


const Login = () => {

    return (
        <main>
            <section className="login-form-section">
                <div className="container-fluid px-0">
                    <div className="row vh-100">
                        <div className="col-md-5 position-relative login-content-wrap">
                            <div className="login-content">
                                <a href="#" className="d-block">
                                    <picture>
                                        <Image src={Logo} alt="logo" className="img-fluid" />
                                    </picture>
                                </a>
                                <h1 className="fw-semibold">AI Solutions that are Fast, Scalable and Flexible</h1>
                                <p className="m-0">The Secret Sauce to power your AI ecosystem Builder and create Impactful human engagements.</p>
                            </div>
                            <div className="login-image">
                                <picture>
                                    <Image src={LoginMember} alt="logo" width={679} height={679} className="img-fluid" />
                                </picture>
                            </div>
                        </div>
                        <div className="col-md-7 login-form-wrap">
                            <LoginCard />
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Login;
