// src/context/SocketContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { BACKEND_SOCKET_URL } from '../constant';
import { useAuthContext } from './AuthContext';


interface SocketContextType {
    socket: Socket | null;
}

const SocketContext = createContext<SocketContextType | undefined>(undefined);

export const useSocket = (): SocketContextType => {
    const context = useContext(SocketContext);
    if (!context) {
        throw new Error('useSocket must be used within a SocketProvider');
    }
    return context;
};

interface SocketProviderProps {
    children: React.ReactNode;
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
    const { user } = useAuthContext();
    const [socket, setSocket] = useState<Socket | null>(null);

    useEffect(() => {
        if (user?._id) {
            const newSocket = io(`${BACKEND_SOCKET_URL}/?id=${user._id}`); // Replace with your server URL

            newSocket.on('connect', () => {
                console.log(`Connected successfully to socket server with ID: ${user._id}`);
            });

            newSocket.on('connect_error', (error) => {
                console.error(`Socket connection failed: ${error.message}`);
            });

            setSocket(newSocket);

            return () => {
                newSocket.close();
            };
        }
    }, [user]);

    return (
        <SocketContext.Provider value={{ socket }}>
            {children}
        </SocketContext.Provider>
    );
};
