import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthContext } from './context/AuthContext';

interface ProtectedRouteProps {
    element: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
    const { isAuthenticated } = useAuthContext();
    const location = useLocation();

    if (!isAuthenticated()) {
        // Redirect to login page and save the current location they were trying to go to
        return <Navigate to="/login" state={{ from: location }} />;
    }

    return element;
};

export default ProtectedRoute;
