import axiosInstance from "./axiosInterceptor";

export const listNotifications = async (id: string) => {
    try {
      const { data } = await axiosInstance.get(`/notifications/${id}`);
      if(data) return data.data;
      return [];
    } catch (error) {
      console.error("Error fetching listNotifications:", error);
      throw error;
    }
  };


  export const getNotificationCount=async(id:string)=>{
    
    try {
        const { data } = await axiosInstance.get(`/unread-notification/${id}`);
        if(data) return data.data;
      } catch (error) {
        console.error("Error fetching getNotificationCount:", error);
        throw error;
      }
  }


  export const deleteNotification=async(id:string)=>{
    try {
        const { data } = await axiosInstance.delete(`/delete-notification/${id}`);
        if(data) return data.data;
      } catch (error) {
        console.error("Error deleting notification:", error);
        throw error;
      }
  }