import React from 'react';
import Button from './Button';

interface CustomModalProps {
    show: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

function onCloses(){
    document.body.classList.remove('popupshow')
}
const Modal: React.FC<CustomModalProps> = ({ show, onClose, children }) => {
    if (!show) return null;

    return (
        <div className="custom-modal-overlay z-2" onClick={onClose}>
            <div className="custom-modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="custom-modal-header justify-content-between align-items-center">
                    <h1 className="modal-title">Schedule Invite</h1>
                    <Button type="button" className="custom-modal-close border-0 bg-transparent" 
                    onClick={() => {
                            onCloses();
                            onClose();
                        }} 
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
                            <path d="M14.49 2.00008L2 14.4901M14.49 14.49L2 2" stroke="#000519" stroke-width="2.20794" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Button>
                </div>
                <div className="custom-modal-body">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;
